export default {
  followInstructionsInEmail: 'Por favor sigue las instrucciones en tu email',
  resetPassword: 'Reiniciar contraseña',
  youAreLoggedIn: 'Ya estas conectado',
  goHome: 'Volver',
  signOut: 'Salir',
  password: 'Contraseña',
  forgotMyPassword: 'Olvidé mi contraseña',
  createAnAccount: 'Crear un cuenta',
  login: 'Entrar',
  name: 'Nombre',
  lastName: 'Apellido',
  createAccount: 'Crear cuenta',
  ifYouHaveAnAccount: 'Si tienes una cuenta',
  yourPasswordHasBeenChanged: 'Tu contraseña fue cambiada',
  resetLinkExpired: 'El link para reiniciar tu contraseña expiró, por favor empieza de nuevo',
  newPassword: 'Nueva contraseña',
  passwordRequirements: 'Tu contraseña debe tener al menos 8 caracteres de largo',
  confirmPassword: 'Confirma tu contraseña',
  emailVerficationCodeExpired: 'El código de verificación expiró',
  weAreVerifyingYourEmail: 'Estamos verificado tu email'
}
