import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import useOrganizationId from './useOrganizationId'

export default function(role, areaId) {
  const organizationId = useOrganizationId()
  const {me} = useQuery(
    gql`
      query getRoleInOrganization($organizationId: ID) {
        me {
          _id
          role: roleInOrganization(organizationId: $organizationId) {
            role
            areas {
              _id
              name
            }
          }
        }
      }
    `,
    {organizationId},
    {fetchPolicy: 'cache-first'}
  )

  if (!me) return false
  if (!me.role) return false

  if (areaId && me.role.role !== 'admin' && !me.role.areas.find(area => area._id === areaId)) {
    return false
  }

  const roles = {
    admin: ['admin'],
    editor: ['admin', 'editor'],
    viewer: ['admin', 'editor', 'viewer']
  }[role]

  return roles.includes(me.role.role)
}
