'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSession = exports.sessionQuery = exports.setSession = exports.createClient = undefined;

var _createClient = require('./apollo/createClient');

var _createClient2 = _interopRequireDefault(_createClient);

var _setSession = require('./auth/setSession');

var _setSession2 = _interopRequireDefault(_setSession);

var _getSession = require('./auth/getSession');

var _getSession2 = _interopRequireDefault(_getSession);

var _sessionQuery = require('./auth/sessionQuery');

var _sessionQuery2 = _interopRequireDefault(_sessionQuery);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.createClient = _createClient2.default;
exports.setSession = _setSession2.default;
exports.sessionQuery = _sessionQuery2.default;
exports.getSession = _getSession2.default;