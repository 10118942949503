import React from 'react'
import useRouter from 'App/hooks/useRouter'
import MutationButton from 'App/components/MutationButton'
import useOrganizationId from 'App/hooks/useOrganizationId'
import useHasPermissions from 'App/hooks/useHasPermissions'

export default function Delete(props) {
  const {contractId} = props
  const {history} = useRouter()
  const organizationId = useOrganizationId()
  const hasPermissions = useHasPermissions('admin')
  if (!hasPermissions) return null
  return (
    <MutationButton
      label="Eliminar documento"
      title="Eliminar documento"
      confirmText="Eliminar documento"
      message="Seguro que quieres eliminar este documento?"
      params={{contractId}}
      mutation="deleteContract"
      danger
      onSuccess={() => history.push(`/${organizationId}/contracts`)}
    />
  )
}
