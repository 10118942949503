import React from 'react'
import styles from './styles.css'
import PaginatedList from 'App/components/Crud/List'
import Button from 'orionsoft-parts/lib/components/Button'
import useOrganizationId from 'App/hooks/useOrganizationId'
import useHasPermissions from 'App/hooks/useHasPermissions'

export default function Users(props) {
  const fields = [
    {name: 'name', title: 'Nombre'},
    {
      name: 'rut',
      title: 'Rut'
    },
    {
      name: 'isActive',
      title: 'Activo',
      render: ({isActive}) => (isActive ? 'Si' : 'No')
    }
  ]
  const hasPermissions = useHasPermissions('admin')
  const organizationId = useOrganizationId()
  return (
    <div className={styles.container}>
      <PaginatedList
        title="Firmantes"
        name="signers"
        canUpdate={hasPermissions}
        params={{organizationId}}
        centerContent={
          hasPermissions ? (
            <Button primary to={`/${organizationId}/signers/create`}>
              Crear
            </Button>
          ) : null
        }
        fields={fields}
        allowSearch
        basePath={`/${organizationId}/signers`}
      />
    </div>
  )
}
