import React from 'react'
import Select from 'orionsoft-parts/lib/components/fields/Select'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import useOrganizationId from 'App/hooks/useOrganizationId'

export default function ContractType(props) {
  const {organization} = useQuery(
    gql`
      query getOrganizationAreas($organizationId: ID) {
        organization(organizationId: $organizationId) {
          _id
          contractTypes
        }
      }
    `,
    {organizationId: useOrganizationId()}
  )
  const options = organization.contractTypes
    ? organization.contractTypes.map(contractType => ({
        label: contractType,
        value: contractType
      }))
    : []
  return <Select {...props} options={options} />
}
