import React from 'react'
import styles from './styles.css'
import User from './User'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import useOrganizationId from 'App/hooks/useOrganizationId'

export default function List(props) {
  const {roles, refetch} = useQuery(
    gql`
      query getOrganizationUsers($organizationId: ID) {
        roles(organizationId: $organizationId) {
          _id
          role
          user {
            _id
            name
            email
          }
        }
      }
    `,
    {organizationId: useOrganizationId()}
  )
  return (
    <div className={styles.container}>
      <div className="row">
        {roles.map(user => (
          <div key={user._id} className="col-xs-12 col-sm-6 col-md-4">
            <User user={user} refetch={refetch} />
          </div>
        ))}
      </div>
    </div>
  )
}
