'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _apolloCacheInmemory = require('apollo-cache-inmemory');

exports.default = new _apolloCacheInmemory.InMemoryCache({
  cacheRedirects: {
    Query: {}
  }
});