import {useState, useLayoutEffect, useEffect} from 'react'
import useWidth from './useWidth'
import useInterval from './useInterval'

function getSize(el) {
  if (!el) return
  return el.offsetHeight
}

export default function useComponentSize(ref) {
  const [height, setComponentSize] = useState(getSize(ref.current))
  useWidth()

  function handleResize() {
    if (ref && ref.current) {
      const newHeight = getSize(ref.current)
      if (newHeight !== height) {
        setComponentSize(newHeight)
      }
    }
  }

  useEffect(() => {
    handleResize()
  })

  useLayoutEffect(() => {
    handleResize()
  })

  useInterval(() => {
    if (!height) {
      handleResize()
    }
  }, 10)

  return height
}
