import React from 'react'
import SelectOrganization from './SelectOrganization'
import Layout from './Layout'
import links from '../links'

export default function AdminLayout(props) {
  return (
    <SelectOrganization {...props}>
      <Layout categories={links}>{props.children}</Layout>
    </SelectOrganization>
  )
}
