'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  query getLocalSession {\n    orionjsSession @client {\n      _id\n      publicKey\n      secretKey\n      createdAt\n      userId\n      locale\n      roles\n      emailVerified\n      __typename\n    }\n  }\n'], ['\n  query getLocalSession {\n    orionjsSession @client {\n      _id\n      publicKey\n      secretKey\n      createdAt\n      userId\n      locale\n      roles\n      emailVerified\n      __typename\n    }\n  }\n']);

var _graphqlTag = require('graphql-tag');

var _graphqlTag2 = _interopRequireDefault(_graphqlTag);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

exports.default = (0, _graphqlTag2.default)(_templateObject);