import React from 'react'
import Context from './Context'
import Select from './Select'
import useRouter from 'App/hooks/useRouter'

export default function SelectOrganization(props) {
  const {match} = useRouter()
  const {organizationId} = match.params
  if (!organizationId) return <Select />
  return <Context.Provider value={organizationId}>{props.children}</Context.Provider>
}
