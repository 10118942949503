import React from 'react'
import {Route, Redirect, Switch} from 'react-router-dom'
import Home from './Home'
import Settings from './Settings'
import useForceLogin from 'App/hooks/useForceLogin'
import Layout from './Layout'
import Roles from './Roles'
import Organization from './Organization'
import CreateContract from './CreateContract'
import Contracts from './Contracts'
import Entities from './Entities'
import Signers from './Signers'
import Areas from './Areas'
import Alarms from './Alarms'

export default function App(props) {
  useForceLogin()
  return (
    <Route path="/:organizationId?">
      <Layout {...props}>
        <Switch>
          <Redirect path="/:organizationId" to="/:organizationId/contracts" exact />
          <Route path="/:organizationId/contracts/create" component={CreateContract} />
          <Route path="/:organizationId/contracts" component={Contracts} />
          <Route path="/:organizationId/alarms" component={Alarms} />
          <Route path="/:organizationId/users" component={Roles} />
          <Route path="/:organizationId/settings" component={Settings} />
          <Route path="/:organizationId/areas" component={Areas} />
          <Route path="/:organizationId/entities" component={Entities} />
          <Route path="/:organizationId/signers" component={Signers} />
          <Route path="/:organizationId/organization-settings" component={Organization} />
          <Route path="/" component={Home} />
        </Switch>
      </Layout>
    </Route>
  )
}
