import React from 'react'
import styles from './styles.css'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import Section from 'App/components/Section'
import AutoForm from 'App/components/AutoForm'
import useRef from 'App/hooks/useRef'
import Button from 'orionsoft-parts/lib/components/Button'
import useMessage from 'App/hooks/useMessage'
import useRouter from 'App/hooks/useRouter'

export default function Update(props) {
  const {entityId} = props
  const showMessage = useMessage()
  const {history} = useRouter()
  const {entity} = useQuery(
    gql`
      query getOrganization($entityId: ID) {
        entity(entityId: $entityId) {
          _id
          name
          rut
        }
      }
    `,
    {entityId}
  )
  const form = useRef()

  return (
    <div className={styles.container}>
      <Section top title="Editar contraparte" description="Editar contraparte">
        <AutoForm
          mutation="updateEntity"
          ref={form}
          only="entity"
          doc={{entityId, entity}}
          onSuccess={entity => {
            showMessage('Contraparte actualizada')
            history.push(`/${entity.organizationId}/entities`)
          }}
        />
        <br />
        <Button onClick={() => form.current.submit()} primary>
          Guardar
        </Button>
      </Section>
    </div>
  )
}
