import React from 'react'
import styles from './styles.css'
import Delete from './Delete'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import Breadcrumbs from 'App/components/Breadcrumbs'
import Container from 'orionsoft-parts/lib/components/Container'
import Update from './Update'

export default function Organization(props) {
  const {entityId} = props.match.params
  const {entity} = useQuery(
    gql`
      query getEntity($entityId: ID) {
        entity(entityId: $entityId) {
          _id
          name
          organizationId
        }
      }
    `,
    {entityId}
  )
  return (
    <div className={styles.container}>
      <Breadcrumbs past={{[`/${entity.organizationId}/entities`]: 'Contrapartes'}}>
        {entity.name}
      </Breadcrumbs>
      <br />
      <Container>
        <Update entityId={entityId} />
        <Delete entityId={entityId} />
      </Container>
    </div>
  )
}
