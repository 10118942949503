import React from 'react'
import styles from './styles.css'
import Breadcrumbs from 'App/components/Breadcrumbs'
import useOrganizationId from 'App/hooks/useOrganizationId'
import Button from 'orionsoft-parts/lib/components/Button'
import Container from 'orionsoft-parts/lib/components/Container'

export default function NotFound(props) {
  const organizationId = useOrganizationId()
  return (
    <div className={styles.container}>
      <Breadcrumbs past={{[`/${organizationId}/contracts`]: 'Documentos'}}>
        No encontrado
      </Breadcrumbs>
      <Container>
        <p>No se encontró el documento</p>
        <Button to={`/${organizationId}/contracts`}>Volver</Button>
      </Container>
    </div>
  )
}
