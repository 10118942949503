import React from 'react'
import styles from './styles.css'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import Breadcrumbs from 'App/components/Breadcrumbs'
import Container from 'orionsoft-parts/lib/components/Container'
import Update from './Update'
import ContractTypes from './ContractTypes'
import AlarmTypes from './AlarmTypes'

export default function Organization(props) {
  const {organizationId} = props.match.params
  const {organization} = useQuery(
    gql`
      query getOrganization($organizationId: ID) {
        organization(organizationId: $organizationId) {
          _id
          name
        }
      }
    `,
    {organizationId}
  )
  return (
    <div className={styles.container}>
      <Breadcrumbs>{organization.name}</Breadcrumbs>
      <br />
      <Container>
        <ContractTypes organizationId={organizationId} />
        <AlarmTypes organizationId={organizationId} />
        <Update organizationId={organizationId} />
      </Container>
    </div>
  )
}
