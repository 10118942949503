import fetch from 'unfetch'
import getAuthHeaders from '@orion-js/graphql-client/lib/auth/getAuthHeaders'
import url from 'App/Root/url'
import {useEffect} from 'react'
import useState from './useState'

const sleep = time => new Promise(resolve => setTimeout(resolve, time))

const getImage = async function({contractId, page}) {
  const response = await fetch(`${url}/contracts-image/${contractId}/${page}`, {
    method: 'POST',
    dataType: 'binary',
    body: contractId + '-' + page,
    headers: getAuthHeaders(contractId + '-' + page)
  })
  if (response.status !== 200) {
    throw new Error('Error fetching image:' + (await response.text()))
  }
  const body = await response.blob()
  const reader = new window.FileReader()
  reader.readAsDataURL(body)
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

const getImageOnce = async params => {
  try {
    return await getImage(params)
  } catch (error) {
    console.log('Error fetching image:', params, error)
    await sleep(500)
    return await getImageOnce(params)
  }
}

export default function({contractId, page, visible}) {
  const [image, setImage] = useState()
  const download = async () => {
    const result = await getImageOnce({contractId, page})
    setImage(result)
  }
  useEffect(() => {
    if (visible && !image) {
      download()
    }
  }, [contractId, page, visible])
  return image
}
