import React from 'react'
import styles from './styles.css'
import {Route, Switch} from 'react-router-dom'
import List from './List'
import Create from './Create'
import Signer from './Signer'

export default function Signers() {
  return (
    <div className={styles.container}>
      <Switch>
        <Route path="/:organizationId/signers" exact component={List} />
        <Route path="/:organizationId/signers/create" exact component={Create} />
        <Route path="/:organizationId/signers/:signerId" exact component={Signer} />
      </Switch>
    </div>
  )
}
