import React from 'react'
import styles from './styles.css'
import Button from 'orionsoft-parts/lib/components/Button'
import ExportIcon from 'react-icons/lib/fa/file-excel-o'
import CreateIcon from 'react-icons/lib/fa/plus'
import Container from 'orionsoft-parts/lib/components/Container'
import downloadFile from './downloadFile'
import useOrganizationRole from 'App/hooks/useOrganizationRole'
import useOrganizationId from 'App/hooks/useOrganizationId'

export default function Export(props) {
  const role = useOrganizationRole()
  const organizationId = useOrganizationId()
  return (
    <div className={styles.container}>
      <Container>
        {['editor', 'admin'].includes(role) ? (
          <Button
            to={`/${organizationId}/contracts/create`}
            icon={CreateIcon}
            style={{marginRight: 10}}>
            Crear documento
          </Button>
        ) : null}

        <Button onClick={() => downloadFile(props.params)} icon={ExportIcon}>
          Exportar
        </Button>
      </Container>
    </div>
  )
}
