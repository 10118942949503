import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Profile from './Profile'
import Tabs from 'orionsoft-parts/lib/components/Tabs'
import styles from './styles.css'
import Breadcrumbs from 'App/components/Breadcrumbs'
import Container from 'orionsoft-parts/lib/components/Container'
import Security from './Security'
import Translate from 'App/i18n'
import useForceLogin from 'App/hooks/useForceLogin'
import useOrganizationId from 'App/hooks/useOrganizationId'

export default function Settings() {
  useForceLogin()
  const organizationId = useOrganizationId()

  const basePath = `/${organizationId}`
  return (
    <div>
      <div className={styles.header}>
        <Breadcrumbs>
          <Translate tr="settings.title" />
        </Breadcrumbs>
        <br />
        <Tabs
          items={[
            {title: <Translate tr="settings.profile" />, path: basePath + '/settings'},
            {title: <Translate tr="settings.security" />, path: basePath + '/settings/security'}
          ]}
        />
      </div>
      <Container>
        <Switch>
          <Route exact path={basePath + '/settings'} component={Profile} />
          <Route path={basePath + '/settings/security'} component={Security} />
        </Switch>
      </Container>
    </div>
  )
}
