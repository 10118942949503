import React from 'react'
import styles from './styles.css'

export default function TableInfo(props) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.title}</div>
      {props.items.map((item, index) => (
        <div key={index} className={styles.line}>
          <div>{item.label}</div>
          <div>{item.value}</div>
        </div>
      ))}
    </div>
  )
}
