import React from 'react'
import Button from 'orionsoft-parts/lib/components/Button'
import DownloadIcon from 'react-icons/lib/md/file-download'
import downloadFile from './downloadFile'
import useHasPermissions from 'App/hooks/useHasPermissions'

export default function Export(props) {
  const hasPermissions = useHasPermissions('editor')
  if (!hasPermissions) return null
  return (
    <Button onClick={() => downloadFile(props)} icon={DownloadIcon}>
      Descargar
    </Button>
  )
}
