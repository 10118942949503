import React from 'react'
import styles from './styles.css'
import MenuIcon from 'react-icons/lib/md/menu'

export default function MenuButton(props) {
  return (
    <div className={styles.container} onClick={() => props.setMenuOpen(!props.menuOpen)}>
      <MenuIcon size={30} />
    </div>
  )
}
