import fetch from 'unfetch'
import getAuthHeaders from '@orion-js/graphql-client/lib/auth/getAuthHeaders'
import url from 'App/Root/url'
import download from 'downloadjs'

export default async function({contractId, name}) {
  const body = JSON.stringify({contractId})
  const response = await fetch(`${url}/download-contract`, {
    method: 'POST',
    dataType: 'binary',
    body,
    headers: getAuthHeaders(body)
  })

  const blob = await response.blob()

  download(blob, `${name}.pdf`, 'application/pdf')
}
