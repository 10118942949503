const options = [
  {label: 'Diario', value: 'day'},
  {label: 'Semanal', value: 'week'},
  {label: 'Mensual', value: 'month'},
  {label: 'Anual', value: 'year'}
]

const labels = {}

for (const {label, value} of options) {
  labels[value] = label
}

export default labels
