'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

exports.default = function (body) {
  var getHeaders = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
  var session = (0, _getSession2.default)();
  var headers = getHeaders(body);
  if (!session) return _extends({}, headers);
  var publicKey = session.publicKey,
      secretKey = session.secretKey;
  if (!publicKey || !secretKey) return _extends({}, headers);
  var nonce = new Date().getTime();
  var signature = (0, _getSignature2.default)(nonce + body, session);
  return _extends({
    'X-ORION-NONCE': nonce,
    'X-ORION-PUBLICKEY': publicKey,
    'X-ORION-SIGNATURE': signature
  }, headers);
};

var _getSession = require('./getSession');

var _getSession2 = _interopRequireDefault(_getSession);

var _getSignature = require('./getSignature');

var _getSignature2 = _interopRequireDefault(_getSignature);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}