import React from 'react'
import styles from './styles.css'
import PaginatedList from 'App/components/Crud/List'
import useOrganizationId from 'App/hooks/useOrganizationId'
import useRouter from 'App/hooks/useRouter'
import moment from 'moment'
import Filter from './Filter'
import useState from 'App/hooks/useState'
import Tooltip from 'orionsoft-parts/lib/components/Tooltip'
import Export from './Export'
import Header from 'App/components/Header'

export default function Alarms(props) {
  const [filters, setFilters] = useState({})
  const fields = [
    {
      name: '_id',
      title: 'Estado',
      render: ({isActive, isDue}) => {
        let color = '#ccc'
        let text = null
        if (!isActive && !isDue) {
          color = 'green'
          text = 'OK'
        }
        if (isActive) {
          color = 'orange'
          text = 'Activa'
        }
        if (isDue) {
          color = 'red'
          text = 'Vencida'
        }
        return (
          <div className={styles.indicatorContainer}>
            <Tooltip content={text}>
              <div className={styles.indicator} style={{backgroundColor: color}} />
            </Tooltip>
          </div>
        )
      }
    },
    {name: 'alarmType', title: 'Tipo'},
    {name: 'dueDate', title: 'Fecha', render: ({dueDate}) => moment(dueDate).format('LL')},
    {name: 'description', title: 'Descripción'},
    {name: 'text', title: 'Cláusula'},
    {name: 'contract.name', title: 'Documento'},
    {name: 'contract.number', title: 'ID'},
    {name: 'contract.area.name', title: 'Área'},
    {
      name: 'lastResolved.resolvedAt',
      title: 'Ultima fecha resuelta',
      render: ({lastResolved}) =>
        lastResolved ? moment(lastResolved.resolvedAt).format('LLL') : '-'
    },
    {
      name: 'lastResolved.resolvedByName',
      title: 'Resuelta por',
      render: ({lastResolved}) => (lastResolved ? lastResolved.resolvedByName : '-')
    }
  ]
  const organizationId = useOrganizationId()
  const {history} = useRouter()
  return (
    <div className={styles.container}>
      <Header title="Alarmas">
        <Filter filters={filters} setFilters={setFilters} />
      </Header>
      <PaginatedList
        title={null}
        name="alarms"
        params={{organizationId, ...filters}}
        fields={fields}
        allowSearch={false}
        centerContent={null}
        extraFields={[
          'isActive',
          'isDue',
          'contractId',
          'contract._id',
          'contract.area._id',
          'lastResolved._id'
        ]}
        onSelect={({contractId}) =>
          history.push(`/${organizationId}/contracts/${contractId}/alarms`)
        }
        basePath={`/${organizationId}/alarms`}
      />
      <Export params={{organizationId, ...filters}} />
    </div>
  )
}
