import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import useOrganizationId from './useOrganizationId'

export default function() {
  const organizationId = useOrganizationId()
  const {me} = useQuery(
    gql`
      query getRoleInOrganization($organizationId: ID) {
        me {
          _id
          role: roleInOrganization(organizationId: $organizationId) {
            role
            areas {
              _id
              name
            }
          }
        }
      }
    `,
    {organizationId},
    {fetchPolicy: 'cache-first'}
  )

  if (!me) return null
  if (!me.role) return null

  return me.role.role
}
