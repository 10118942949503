import React from 'react'
import styles from './styles.css'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import LogoutIcon from 'react-icons/lib/md/exit-to-app'
import BackIcon from 'react-icons/lib/md/arrow-back'
import logout from 'App/helpers/auth/logout'
import {withRouter} from 'react-router'
import MenuButton from '../MenuButton'

@withRouter
export default class Menu extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    environment: PropTypes.object,
    links: PropTypes.array,
    categories: PropTypes.array,
    title: PropTypes.node,
    rootPath: PropTypes.string,
    backPath: PropTypes.string,
    open: PropTypes.bool,
    menuOpen: PropTypes.bool,
    setMenuOpen: PropTypes.func,
    settingsPath: PropTypes.string
  }

  static defaultProps = {
    settingsPath: '/settings'
  }

  renderLink({title, path}, useFullToCheck) {
    const active = useFullToCheck
      ? this.props.location.pathname === path
      : this.props.location.pathname.startsWith(path)
    return (
      <Link
        key={path}
        to={path}
        onClick={() => this.props.setMenuOpen(false)}
        className={active ? styles.itemActive : styles.menuItem}>
        {title}
      </Link>
    )
  }

  renderLinks(links) {
    return links.map(link => {
      return this.renderLink(link, true)
    })
  }

  renderCategories() {
    return this.props.categories.map((category, index) => {
      const Icon = category.icon
      return (
        <div key={index}>
          <div className={styles.categoryTitle}>
            <Icon size={16} /> <span className={styles.categoryTitleText}>{category.title}</span>
          </div>
          <div className={styles.categoryItems}>{this.renderLinks(category.items)}</div>
        </div>
      )
    })
  }

  renderBack() {
    if (!this.props.backPath) return
    return (
      <div className={styles.logout} onClick={() => this.props.history.push(this.props.backPath)}>
        <BackIcon />
        <span style={{marginLeft: 5}}>Volver</span>
      </div>
    )
  }

  renderLogout() {
    if (this.props.backPath) return
    return (
      <div className={styles.logout} onClick={logout}>
        <LogoutIcon />
        <span style={{marginLeft: 5}}>Salir</span>
      </div>
    )
  }

  renderSettings() {
    if (this.props.backPath) return
    return this.renderLink({title: 'Mi cuenta', path: this.props.settingsPath})
  }

  render() {
    if (!this.props.menuOpen) return null
    return (
      <div className={styles.container}>
        <MenuButton menuOpen={this.props.menuOpen} setMenuOpen={this.props.setMenuOpen} />
        <Link to={this.props.rootPath} className={styles.title}>
          {this.props.title}
        </Link>
        <div className={styles.divider} />
        <div className={styles.menuInner}>
          {this.props.links && this.renderLinks(this.props.links)}
          {this.props.categories && this.renderCategories()}
          <div className={styles.divider} />
          {this.renderSettings()}
          {this.renderBack()}
          {this.renderLogout()}
        </div>
      </div>
    )
  }
}
