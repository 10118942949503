import React from 'react'
import styles from './styles.css'
import {Route, Switch} from 'react-router-dom'
import List from './List'
import Create from './Create'
import Entity from './Entity'

export default function Entities() {
  return (
    <div className={styles.container}>
      <Switch>
        <Route path="/:organizationId/entities" exact component={List} />
        <Route path="/:organizationId/entities/create" exact component={Create} />
        <Route path="/:organizationId/entities/:entityId" exact component={Entity} />
      </Switch>
    </div>
  )
}
