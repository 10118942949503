import React from 'react'
import styles from './styles.css'
import Button from 'orionsoft-parts/lib/components/Button'
import ExportIcon from 'react-icons/lib/fa/file-excel-o'
import Container from 'orionsoft-parts/lib/components/Container'
import downloadFile from './downloadFile'

export default function Export(props) {
  return (
    <div className={styles.container}>
      <Container>
        <Button onClick={() => downloadFile(props.params)} icon={ExportIcon}>
          Exportar
        </Button>
      </Container>
    </div>
  )
}
