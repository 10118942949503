import React from 'react'
import Button from 'orionsoft-parts/lib/components/Button'
import AutoForm from 'App/components/AutoForm'
import {Field} from 'simple-react-form'
import Text from 'orionsoft-parts/lib/components/fields/Text'
import Select from 'orionsoft-parts/lib/components/fields/Select'
import useOrganizationId from 'App/hooks/useOrganizationId'
import useState from 'App/hooks/useState'
import useRef from 'App/hooks/useRef'
import useHasPermissions from 'App/hooks/useHasPermissions'

export default function Add() {
  const organizationId = useOrganizationId()
  const [open, setOpen] = useState()
  const form = useRef()
  const options = [
    {label: 'Admin', value: 'admin'},
    {label: 'Editor', value: 'editor'},
    {label: 'Visor', value: 'viewer'}
  ]
  const hasPermissions = useHasPermissions('admin')

  if (!hasPermissions) return null

  if (!open) {
    return (
      <Button onClick={() => setOpen(true)} primary>
        Agregar usuario a la organización
      </Button>
    )
  }
  return (
    <div>
      <AutoForm
        mutation="createRole"
        ref={form}
        refetchQueries={['getOrganizationUsers']}
        doc={{organizationId}}
        onSuccess={() => setOpen(false)}>
        <div className="row">
          <div className="col-xs-12 col-sm-3">
            <Field fieldName="email" type={Text} fieldType="email" label="Email" />
          </div>
          <div className="col-xs-12 col-sm-3">
            <Field fieldName="role" type={Select} label="Rol" options={options} />
          </div>
          <div className="col-xs-12 col-sm-3">
            <div style={{height: 32}} />
            <Button onClick={() => setOpen(false)}>Cancelar</Button>
            <Button primary onClick={() => form.current.submit()}>
              Agregar
            </Button>
          </div>
        </div>
      </AutoForm>
    </div>
  )
}
