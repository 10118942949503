import React from 'react'
import styles from './styles.css'
import Delete from './Delete'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import Breadcrumbs from 'App/components/Breadcrumbs'
import Container from 'orionsoft-parts/lib/components/Container'
import Update from './Update'

export default function Organization(props) {
  const {signerId} = props.match.params
  const {signer} = useQuery(
    gql`
      query getSigner($signerId: ID) {
        signer(signerId: $signerId) {
          _id
          name
          organizationId
          isActive
        }
      }
    `,
    {signerId}
  )
  return (
    <div className={styles.container}>
      <Breadcrumbs past={{[`/${signer.organizationId}/signers`]: 'Firmantes'}}>
        {signer.name}
      </Breadcrumbs>
      <br />
      <Container>
        <Update signerId={signerId} />
        <Delete signerId={signerId} />
      </Container>
    </div>
  )
}
