import React from 'react'
import styles from './styles.css'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import Section from 'App/components/Section'
import AutoForm from 'App/components/AutoForm'
import useRef from 'App/hooks/useRef'
import Button from 'orionsoft-parts/lib/components/Button'
import useMessage from 'App/hooks/useMessage'
import useRouter from 'App/hooks/useRouter'

export default function Update(props) {
  const {areaId} = props
  const showMessage = useMessage()
  const {history} = useRouter()
  const {area} = useQuery(
    gql`
      query getArea($areaId: ID) {
        area(areaId: $areaId) {
          _id
          name
        }
      }
    `,
    {areaId}
  )
  const form = useRef()

  return (
    <div className={styles.container}>
      <Section top title="Editar área" description="Editar área">
        <AutoForm
          mutation="updateArea"
          ref={form}
          only="area"
          doc={{areaId, area}}
          onSuccess={area => {
            showMessage('Área actualizada')
            history.push(`/${area.organizationId}/areas`)
          }}
        />
        <br />
        <Button onClick={() => form.current.submit()} primary>
          Guardar
        </Button>
      </Section>
    </div>
  )
}
