import React, {Fragment} from 'react'
import styles from './styles.css'
import useContractImage from 'App/hooks/useContractImage'

export default function Image(props) {
  const url = useContractImage(props)
  return (
    <Fragment>
      <img src={url} className={styles.image} />
      <div className={url ? styles.notLoading : styles.loading} />
    </Fragment>
  )
}
