import {createClient} from '@orion-js/graphql-client'
import url from './url'
import TwoFactorPromptProvider from './TwoFactorPromptProvider'
import JSSHA from 'jssha'
global.JSSHA = JSSHA

// use unique session key per project to avoid
// loging in and out every time you change projects
// in localhost
const sessionKey = 'orionjs.contracts.session'

export default createClient({
  endpointURL: url,
  useSubscriptions: false,
  batch: false,
  promptTwoFactorCode: TwoFactorPromptProvider.promptTwoFactor,
  saveSession(session) {
    localStorage.setItem(sessionKey, JSON.stringify(session, null, 2))
  },
  getSession(session) {
    try {
      return JSON.parse(localStorage.getItem(sessionKey))
    } catch (e) {
      return {}
    }
  }
})
