const options = [
  {
    label: 'Vigente',
    value: 'valid'
  },
  {
    label: 'No vigente',
    value: 'invalid'
  },
  {
    label: 'Borrador no firmado',
    value: 'pending'
  }
]

const labels = {}

for (const {label, value} of options) {
  labels[value] = label
}

export default labels
