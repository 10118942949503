import React from 'react'
import styles from './styles.css'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import TableInfo from 'App/components/TableInfo'
import moment from 'moment'
import NewLines from 'App/components/NewLines'
import statusLabels from 'App/helpers/labels/status'
import pricePeriodicityLabels from 'App/helpers/labels/pricePeriodicity'
import numeral from 'numeral'
import Delete from './Delete'
import ExpandText from 'App/components/ExpandText'
import Download from './Download'

export default function Info(props) {
  const {contract} = useQuery(
    gql`
      query getContract($contractId: ID) {
        contract(contractId: $contractId) {
          _id
          name
          number
          area {
            _id
            name
          }
          contractType
          description
          pages {
            key
          }
          otherSignersText
          createdBy
          createdAt
          signDate
          dueDate
          status
          notary
          repertory
          price
          pricePeriodicity
          signers {
            _id
            name
            rut
            isActive
          }
          otherSignersEntities {
            _id
            name
            rut
          }
        }
      }
    `,
    props.match.params
  )

  const items = [
    {label: 'ID', value: contract.number},
    {label: 'Nombre', value: contract.name},
    {label: 'Tipo', value: contract.contractType},
    {label: 'Área', value: contract.area ? contract.area.name : '-'},
    {label: 'Estado', value: contract.status ? statusLabels[contract.status] : '-'},
    {
      label: 'Objeto del documento',
      value: contract.description ? (
        <ExpandText>
          <NewLines>{contract.description}</NewLines>
        </ExpandText>
      ) : (
        '-'
      )
    },
    {label: 'Notaría', value: contract.notary || '-'},
    {label: 'Repertorio', value: contract.repertory || '-'},
    {label: 'Páginas', value: contract.pages.length},
    {
      label: 'Fecha de firma',
      value: contract.signDate ? moment(contract.signDate).format('LL') : '-'
    },
    {
      label: 'Fecha de vencimiento',
      value: contract.dueDate ? moment(contract.dueDate).format('LL') : '-'
    },
    {label: 'Fecha de carga', value: moment(contract.createdAt).format('LL')}
  ]
  return (
    <div className={styles.container}>
      <TableInfo title="Documento" items={items} />
      <TableInfo
        title="Precio"
        items={[
          {label: 'Precio', value: contract.price ? numeral(contract.price).format('$0,0') : '-'},
          {
            label: 'Periodicidad',
            value: contract.pricePeriodicity
              ? pricePeriodicityLabels[contract.pricePeriodicity]
              : '-'
          }
        ]}
      />
      <TableInfo
        title="Firmantes"
        items={contract.signers.map(signer => ({label: signer.name, value: signer.rut}))}
      />
      <TableInfo
        title="Contraparte"
        items={contract.otherSignersEntities.map(entity => ({
          label: entity.name,
          value: entity.rut
        }))}
      />
      {contract.otherSignersText ? (
        <TableInfo
          title="Firmantes por la contraparte"
          items={[{label: <NewLines>{contract.otherSignersText}</NewLines>}]}
        />
      ) : null}
      <Download contractId={contract._id} name={contract.name} />
      <Delete contractId={contract._id} />
    </div>
  )
}
