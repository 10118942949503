import React from 'react'
import styles from './styles.css'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import Section from 'App/components/Section'
import AutoForm from 'App/components/AutoForm'
import useRef from 'App/hooks/useRef'
import Button from 'orionsoft-parts/lib/components/Button'
import useMessage from 'App/hooks/useMessage'

export default function Update(props) {
  const {organizationId} = props
  const showMessage = useMessage()
  const {organization} = useQuery(
    gql`
      query getOrganization($organizationId: ID) {
        organization(organizationId: $organizationId) {
          _id
          contractTypes
        }
      }
    `,
    {organizationId}
  )
  const form = useRef()

  return (
    <div className={styles.container}>
      <Section
        top
        title="Editar tipos de documento"
        description="Editar los tipos de documentos disponibles en la organización">
        <AutoForm
          mutation="updateOrganizationContractTypes"
          ref={form}
          only="contractTypes"
          doc={{organizationId, contractTypes: organization.contractTypes}}
          onSuccess={organization => showMessage('Organización actualizada')}
        />
        <br />
        <Button onClick={() => form.current.submit()} primary>
          Guardar
        </Button>
      </Section>
    </div>
  )
}
