import React from 'react'
import styles from './styles.css'
import PaginatedList from 'App/components/Crud/List'
import Button from 'orionsoft-parts/lib/components/Button'
import useOrganizationId from 'App/hooks/useOrganizationId'
import useHasPermissions from 'App/hooks/useHasPermissions'

export default function Users(props) {
  const fields = [
    {name: 'name', title: 'Nombre'},
    {
      name: 'rut',
      title: 'Rut'
    }
  ]
  const hasPermission = useHasPermissions('admin')
  const organizationId = useOrganizationId()
  return (
    <div className={styles.container}>
      <PaginatedList
        title="Contrapartes"
        name="entities"
        canUpdate={hasPermission}
        params={{organizationId}}
        centerContent={
          hasPermission ? (
            <Button primary to={`/${organizationId}/entities/create`}>
              Crear
            </Button>
          ) : null
        }
        fields={fields}
        allowSearch
        basePath={`/${organizationId}/entities`}
      />
    </div>
  )
}
