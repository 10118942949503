import React from 'react'
import styles from './styles.css'
import {Form, Field} from 'simple-react-form'
import Select from 'orionsoft-parts/lib/components/fields/Select'
import Area from 'App/components/fields/Area'
import AlarmType from 'App/components/fields/AlarmType'
import Text from 'orionsoft-parts/lib/components/fields/Text'

export default function Filter(props) {
  return (
    <div className={styles.container}>
      <Form state={props.filters} onChange={props.setFilters}>
        <div className="row">
          <div className="col-xs-12 col-sm-3">
            <Field fieldName="areaId" type={Area} placeholder="Área" />
          </div>
          <div className="col-xs-12 col-sm-3">
            <Field fieldName="alarmType" type={AlarmType} placeholder="Tipo de alarma" />
          </div>
          <div className="col-xs-12 col-sm-3">
            <Field
              fieldName="status"
              type={Select}
              placeholder="Estado"
              options={[
                {
                  label: 'OK',
                  value: 'ok'
                },
                {
                  label: 'Activa',
                  value: 'active'
                },
                {
                  label: 'Vencida',
                  value: 'due'
                }
              ]}
            />
          </div>
          <div className="col-xs-12 col-sm-3">
            <Field fieldName="filter" type={Text} placeholder="Buscar" />
          </div>
        </div>
      </Form>
    </div>
  )
}
