import React from 'react'
import styles from './styles.css'
import Section from 'App/components/Section'
import useRouter from 'App/hooks/useRouter'
import MutationButton from 'App/components/MutationButton'
import useOrganizationId from 'App/hooks/useOrganizationId'

export default function Delete(props) {
  const {entityId} = props
  const {history} = useRouter()
  const organizationId = useOrganizationId()
  return (
    <div className={styles.container}>
      <Section title="Eliminar contraparte" description="Elimina la contraparte">
        <div>Eliminar contraparte</div>
        <br />
        <MutationButton
          label="Eliminar contraparte"
          title="Eliminar contraparte"
          confirmText="Eliminar contraparte"
          message="Seguro que quieres eliminar esta contraparte?"
          params={{entityId}}
          mutation="deleteEntity"
          danger
          onSuccess={() => history.push(`/${organizationId}/entities`)}
        />
      </Section>
    </div>
  )
}
