import React from 'react'
import styles from './styles.css'
import useState from 'App/hooks/useState'
import Tooltip from 'orionsoft-parts/lib/components/Tooltip'

export default function ExpandText(props) {
  const [expanded, setExpanded] = useState()
  return (
    <div className={styles.container}>
      <Tooltip content={expanded ? 'Haz click para comprimir' : 'Haz click para expandir'}>
        <div
          onClick={() => setExpanded(!expanded)}
          className={expanded ? styles.expanded : styles.small}>
          {props.children}
        </div>
      </Tooltip>
    </div>
  )
}
