'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (options) {
  var endpointURL = options.endpointURL,
      subscriptionsPath = options.subscriptionsPath;
  var uri = endpointURL.replace('http', 'ws') + subscriptionsPath;
  var client = new _subscriptionsTransportWs.SubscriptionClient(uri, {
    reconnect: true,
    connectionParams: getConnectionParams
  });
  options.wsClient = client;
  return client;
};

var _subscriptionsTransportWs = require('subscriptions-transport-ws');

var _getSession = require('../auth/getSession');

var _getSession2 = _interopRequireDefault(_getSession);

var _getSignature = require('../auth/getSignature');

var _getSignature2 = _interopRequireDefault(_getSignature);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var getConnectionParams = function getConnectionParams() {
  var params = {};
  var session = (0, _getSession2.default)();

  if (session) {
    var publicKey = session.publicKey,
        secretKey = session.secretKey;

    if (publicKey && secretKey) {
      var nonce = new Date().getTime();
      var signature = (0, _getSignature2.default)(nonce + 'websockethandshake', session);
      params = {
        nonce: nonce,
        publicKey: publicKey,
        signature: signature
      };
    }
  }

  return params;
};