import React from 'react'
import styles from './styles.css'
import Section from 'App/components/Section'
import useRouter from 'App/hooks/useRouter'
import MutationButton from 'App/components/MutationButton'
import useOrganizationId from 'App/hooks/useOrganizationId'

export default function Delete(props) {
  const {signerId} = props
  const {history} = useRouter()
  const organizationId = useOrganizationId()
  return (
    <div className={styles.container}>
      <Section title="Eliminar firmante" description="Elimina el firmante">
        <div>Eliminar firmante</div>
        <br />
        <MutationButton
          label="Eliminar firmante"
          title="Eliminar firmante"
          confirmText="Eliminar firmante"
          message="Seguro que quieres eliminar este firmante?"
          params={{signerId}}
          mutation="deleteSigner"
          danger
          onSuccess={() => history.push(`/${organizationId}/signers`)}
        />
      </Section>
    </div>
  )
}
