import React from 'react'
import Select from 'orionsoft-parts/lib/components/fields/Select'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import useOrganizationId from 'App/hooks/useOrganizationId'

export default function Signers(props) {
  const {signers} = useQuery(
    gql`
      query getSignersForSelect($organizationId: ID) {
        signers(organizationId: $organizationId) {
          _id
          items {
            _id
            name
            rut
            isActive
          }
        }
      }
    `,
    {organizationId: useOrganizationId()}
  )
  const options = signers.items.map(signer => ({
    label: `${signer.name} ${signer.rut}${signer.isActive ? '' : ' (no activo)'}`,
    value: signer._id
  }))
  return <Select {...props} options={options} multi />
}
