import React, {Fragment} from 'react'

export default function NewLines(props) {
  return (props.children || '').split('\n').map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ))
}
