import React from 'react'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import useRouter from 'App/hooks/useRouter'
import styles from 'App/Pages/Auth/styles.css'
import Select from 'orionsoft-parts/lib/components/fields/Select'
import {Field} from 'simple-react-form'

export default function SelectOrganization(props) {
  const {me} = useQuery(gql`
    query {
      me {
        _id
        organizationRoles {
          _id
          role
          value: organizationId
          label: organizationName
        }
      }
    }
  `)

  const {history} = useRouter()

  if (me.organizationRoles.length === 0) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentInner}>
            <div className={styles.logo}>
              <img src="/logo-white.png" alt="Logo Open Legal" />
            </div>
            <div className={styles.center}>No eres parte de ninguna organización</div>
          </div>
        </div>
        <div className={styles.photo} />
      </div>
    )
  }

  if (me.organizationRoles.length === 1) {
    history.replace(`/${me.organizationRoles[0].value}`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentInner}>
          <div className={styles.logo}>
            <img src="/logo-white.png" alt="Logo Open Legal" />
          </div>
          <div>Selecciona una organización</div>
          <Field
            fieldName="organization"
            type={Select}
            onChange={organizationId => history.push(`/${organizationId}`)}
            options={me.organizationRoles}
          />
        </div>
      </div>
      <div className={styles.photo} />
    </div>
  )
}
