import fetch from 'unfetch'
import getAuthHeaders from '@orion-js/graphql-client/lib/auth/getAuthHeaders'
import url from 'App/Root/url'
import download from 'downloadjs'

export default async function(params) {
  const body = JSON.stringify(params)
  const response = await fetch(`${url}/export-contracts`, {
    method: 'POST',
    dataType: 'binary',
    body,
    headers: getAuthHeaders(body)
  })
  const blob = await response.blob()

  download(
    blob,
    'Documentos.xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  )
}
