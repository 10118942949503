import React from 'react'
import styles from './styles.css'
import AutoForm from 'App/components/AutoForm'
import Button from 'orionsoft-parts/lib/components/Button'
import useRef from 'App/hooks/useRef'
import AlarmIcon from 'react-icons/lib/md/alarm-add'

export default function Create(props) {
  const {contractId, refetch, isCreating, setIsCreating} = props
  const form = useRef()
  if (!isCreating) {
    return (
      <Button onClick={() => setIsCreating(true)} icon={AlarmIcon}>
        Crear una alarma
      </Button>
    )
  }
  return (
    <div className={styles.container}>
      <AutoForm
        mutation="createAlarm"
        omit="contractId"
        doc={{contractId, notifyBeforeDays: 30}}
        ref={form}
        onSuccess={() => {
          setIsCreating(false)
          refetch()
        }}
      />
      <br />
      <Button onClick={() => setIsCreating(false)}>Cancelar</Button>
      <Button onClick={() => form.current.submit()} primary>
        Crear
      </Button>
    </div>
  )
}
