import React from 'react'
import styles from './styles.css'
import SideMenu from 'App/components/SideMenu'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import useOrganizationId from 'App/hooks/useOrganizationId'
import useRouter from 'App/hooks/useRouter'
import cloneDeep from 'lodash/cloneDeep'
import SuspenseLoading from 'App/components/SuspenseLoading'
import useOrganizationRole from 'App/hooks/useOrganizationRole'

export default function Layout(props) {
  const {organization} = useQuery(
    gql`
      query getOrganization($organizationId: ID) {
        organization(organizationId: $organizationId) {
          _id
          name
          logo {
            url
            _id
          }
        }
      }
    `,
    {organizationId: useOrganizationId()}
  )

  const {history} = useRouter()
  const role = useOrganizationRole()

  if (!organization) {
    history.replace('/')
    return null
  }

  const title = organization.logo ? (
    <img src={organization.logo.url} className={styles.logo} />
  ) : (
    organization.name
  )

  const categories = cloneDeep(props.categories)

  for (const category of categories) {
    category.items = category.items
      .filter(item => {
        if (role === 'admin') return true
        if (role === 'editor' && item.role !== 'admin') return true
        if (role === 'viewer' && item.role === 'viewer') return true
        return false
      })
      .map(item => ({
        ...item,
        path: `/${organization._id}${item.path}`
      }))
  }

  return (
    <div className={styles.container}>
      <SideMenu
        categories={categories}
        rootPath="/"
        title={title}
        settingsPath={'/' + organization._id + '/settings'}>
        <SuspenseLoading>{props.children}</SuspenseLoading>
      </SideMenu>
    </div>
  )
}
