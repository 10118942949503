import ArrayComponent from 'orionsoft-parts/lib/components/fields/ArrayComponent'
import Text from 'orionsoft-parts/lib/components/fields/Text'
import NumberField from 'orionsoft-parts/lib/components/fields/numeral/Number'
import Select from 'orionsoft-parts/lib/components/fields/Select'
import Checkbox from 'orionsoft-parts/lib/components/fields/Checkbox'
import ObjectField from './ObjectField'
import SixDigitInput from './SixDigitInput'
import File from './File'
import Rut from 'orionsoft-parts/lib/components/fields/Rut'
import Entities from './Entities'
import Textarea from 'orionsoft-parts/lib/components/fields/Textarea'
import Money from 'orionsoft-parts/lib/components/fields/numeral/Money'
import MultipleText from './MultipleText'
import Area from './Area'
import DatePicker from './DatePicker'
import ContractType from './ContractType'
import Signers from './Signers'
import AlarmType from './AlarmType'

export default {
  string: Text,
  checkbox: Checkbox,
  contractType: ContractType,
  alarmType: AlarmType,
  number: NumberField,
  array: ArrayComponent,
  plainObject: ObjectField,
  boolean: Checkbox,
  date: DatePicker,
  sixDigit: SixDigitInput,
  select: Select,
  file: File,
  rut: Rut,
  entities: Entities,
  signers: Signers,
  textarea: Textarea,
  '[string]': MultipleText,
  area: Area,
  money: Money
}
