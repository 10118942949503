import React from 'react'
import styles from './styles.css'
import AutoForm from 'App/components/AutoForm'
import Section from 'App/components/Section'
import Button from 'orionsoft-parts/lib/components/Button'
import Container from 'orionsoft-parts/lib/components/Container'
import Breadcrumbs from 'App/components/Breadcrumbs'
import useRouter from 'App/hooks/useRouter'
import useRef from 'App/hooks/useRef'
import useOrganizationId from 'App/hooks/useOrganizationId'

export default function Create() {
  const {history} = useRouter()
  const form = useRef()
  const organizationId = useOrganizationId()
  return (
    <div className={styles.container}>
      <Breadcrumbs past={{[`/${organizationId}/areas`]: 'Áreas'}}>Nueva área</Breadcrumbs>
      <br />
      <Container>
        <Section top title="Crear área" description="Crear área">
          <AutoForm
            mutation="createArea"
            omit="organizationId"
            doc={{organizationId}}
            ref={form}
            onSuccess={area => history.push(`/${organizationId}/areas/${area._id}`)}
          />
          <br />
          <Button onClick={() => form.current.submit()} primary>
            Crear
          </Button>
        </Section>
      </Container>
    </div>
  )
}
