import React from 'react'
import Menu from './Menu'
import styles from './styles.css'
import useHeight from 'App/hooks/useHeight'
import useState from 'App/hooks/useState'
import MenuButton from './MenuButton'
import useWidth from 'App/hooks/useWidth'

export default function SideMenu(props) {
  const height = useHeight()
  const width = useWidth()
  const [menuOpen, setMenuOpen] = useState(false)
  const open = width > 769 || menuOpen
  return (
    <div className={styles.container} style={{height}}>
      <Menu
        menuOpen={open}
        setMenuOpen={setMenuOpen}
        backPath={props.backPath}
        links={props.links}
        categories={props.categories}
        title={props.title}
        rootPath={props.rootPath || '/'}
        settingsPath={props.settingsPath}
      />
      <div className={styles.content}>
        <MenuButton menuOpen={open} setMenuOpen={setMenuOpen} />
        {props.children}
      </div>
    </div>
  )
}
