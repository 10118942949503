import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import useComponentWidth from 'App/hooks/useComponentWidth'
import useRef from 'App/hooks/useRef'
import useComponentHeight from 'App/hooks/useComponentHeight'

export default function Header(props) {
  const container = useRef()
  const navbar = useRef()
  const width = useComponentWidth(container)
  const height = useComponentHeight(navbar)
  return (
    <div className={styles.container} ref={container}>
      <div className={styles.navbar} style={{width}} ref={navbar}>
        <Container>
          <div className={styles.header}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.child}>{props.children}</div>
          </div>
        </Container>
      </div>
      <div className={styles.background} style={{height}} />
    </div>
  )
}
