'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _apolloLinkError = require('apollo-link-error');

var _apolloLink = require('apollo-link');

var _setSession = require('../auth/setSession');

var _setSession2 = _interopRequireDefault(_setSession);

var _getSession = require('../auth/getSession');

var _getSession2 = _interopRequireDefault(_getSession);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = function (options) {
  return (0, _apolloLinkError.onError)(function (_ref) {
    var graphQLErrors = _ref.graphQLErrors,
        networkError = _ref.networkError,
        response = _ref.response,
        operation = _ref.operation,
        forward = _ref.forward; // console.error('on error')

    if (graphQLErrors) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = graphQLErrors[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var graphQLError = _step.value;

          if (graphQLError.error === 'PermissionsError' && graphQLError.type === 'needsTwoFactorCode') {
            if (options.promptTwoFactorCode) {
              return new _apolloLink.Observable(function (observer) {
                Promise.resolve(options.promptTwoFactorCode()).then(function (code) {
                  operation.setContext(function (_ref2) {
                    var _ref2$headers = _ref2.headers,
                        headers = _ref2$headers === undefined ? {} : _ref2$headers;
                    return {
                      headers: _extends({}, headers, {
                        // Switch out old access token for new one
                        'X-ORION-TWOFACTOR': code
                      })
                    };
                  });
                }).then(function () {
                  var subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer) // Retry last failed request

                  };
                  forward(operation).subscribe(subscriber);
                }).catch(function (error) {
                  // No refresh or client token available, we force user to login
                  observer.error(error);
                });
              });
            }
          }
        } // console.error(graphQLErrors)

      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }

    if (networkError) {
      if (networkError.statusCode === 401 && networkError.result.error === 'AuthError') {
        if (networkError.result.message !== 'nonceIsInvalid') {
          var session = (0, _getSession2.default)();
          console.log('Resetting session: ' + JSON.stringify(networkError.result, null, 2));

          if (session) {
            (0, _setSession2.default)(null);
          }
        } else {
          console.warn('Received too many nonce is invalid');
        }
      } else {
        console.warn('Network error:', networkError);
      }
    }

    return options.onError({
      graphQLErrors: graphQLErrors,
      networkError: networkError,
      response: response,
      operation: operation,
      forward: forward
    });
  });
};