import React from 'react'
import useRouter from 'App/hooks/useRouter'
import MutationButton from 'App/components/MutationButton'

export default function Delete(props) {
  const {alarm, refetch} = props
  const {history} = useRouter()
  return (
    <MutationButton
      label="Eliminar alarma"
      title="Eliminar alarma"
      confirmText="Eliminar alarma"
      message="Seguro que quieres eliminar esta alarma?"
      params={{alarmId: alarm._id}}
      mutation="deleteAlarm"
      danger
      onSuccess={async () => {
        await refetch()
        history.push(`/${alarm.organizationId}/contracts/${alarm.contractId}/alarms`)
      }}
    />
  )
}
