import AutoForm from 'App/components/AutoForm'
import SuspenseLoading from 'App/components/SuspenseLoading'
import React from 'react'
import styles from './styles.css'
import IconButton from 'orionsoft-parts/lib/components/IconButton'
import BackIcon from 'react-icons/lib/md/arrow-back'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import useRouter from 'App/hooks/useRouter'
import Delete from './Delete'
import Button from 'orionsoft-parts/lib/components/Button'
import useRef from 'App/hooks/useRef'
import useMessage from 'App/hooks/useMessage'

export default function Details(props) {
  const {history} = useRouter()
  const form = useRef()
  const showMessage = useMessage()
  const {alarm} = useQuery(
    gql`
      query getAlarm($alarmId: ID) {
        alarm(alarmId: $alarmId) {
          _id
          alarmType
          description
          text
          dueDate
          notifyDate
          organizationId
          notifyBeforeDays
          contractId
          repeatEvery
        }
      }
    `,
    props
  )
  return (
    <div className={styles.container}>
      <IconButton
        tooltip="Volver"
        icon={BackIcon}
        onPress={() =>
          history.push(`/${alarm.organizationId}/contracts/${alarm.contractId}/alarms`)
        }
      />
      <br />
      <br />
      <SuspenseLoading>
        <AutoForm
          mutation="updateAlarm"
          ref={form}
          only="alarm"
          doc={{alarmId: alarm._id, alarm}}
          onSuccess={alarm => {
            showMessage('Alarma actualizada')
            history.push(`/${alarm.organizationId}/contracts/${alarm.contractId}/alarms`)
          }}
        />
      </SuspenseLoading>
      <br />
      <Delete alarm={alarm} refetch={props.refetch} />
      <Button onClick={() => form.current.submit()} primary>
        Guardar
      </Button>
    </div>
  )
}
