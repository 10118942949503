import React from 'react'
import styles from './styles.css'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import Breadcrumbs from 'App/components/Breadcrumbs'
import Container from 'orionsoft-parts/lib/components/Container'
import View from './View'
import Options from './Options'
import SuspenseLoading from 'App/components/SuspenseLoading'
import NotFound from './NotFound'

export default function Contract(props) {
  const {contract} = useQuery(
    gql`
      query getContract($contractId: ID) {
        contract(contractId: $contractId) {
          _id
          name
          organizationId
          pages {
            key
            width
            height
          }
        }
      }
    `,
    props.match.params
  )

  if (!contract) return <NotFound />
  return (
    <div className={styles.container}>
      <Breadcrumbs past={{[`/${contract.organizationId}/contracts`]: 'Documentos'}}>
        {contract.name}
      </Breadcrumbs>
      <br />
      <Container>
        <div className="row">
          <div className="col-xs-12 col-md-7 col-lg-8">
            <SuspenseLoading>
              <View contract={contract} />
            </SuspenseLoading>
          </div>
          <div className="col-xs-12 col-md-5 col-lg-4">
            <SuspenseLoading>
              <Options contract={contract} />
            </SuspenseLoading>
          </div>
        </div>
      </Container>
    </div>
  )
}
