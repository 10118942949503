import React from 'react'
import styles from './styles.css'
import List from './List'
import Create from './Create'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import useState from 'App/hooks/useState'
import Details from './Details'
import useHasPermissions from 'App/hooks/useHasPermissions'

export default function Alarms(props) {
  const {contractId, alarmId} = props.match.params
  const [isCreating, setIsCreating] = useState()
  const {contract, refetch} = useQuery(
    gql`
      query getContractAlarms($contractId: ID) {
        contract(contractId: $contractId) {
          _id
          alarms {
            _id
            alarmType
            description
            text
            dueDate
            notifyDate
            organizationId
            contractId
            isActive
            isDue
            resolvedAt
            resolvedText
            resolvedByName
          }
        }
      }
    `,
    {contractId}
  )
  const hasPermissions = useHasPermissions('editor')
  return (
    <div className={styles.container}>
      {!hasPermissions || alarmId ? null : (
        <Create
          isCreating={isCreating}
          setIsCreating={setIsCreating}
          contractId={contractId}
          refetch={refetch}
        />
      )}
      {isCreating || alarmId ? null : <List refetch={refetch} contract={contract} />}
      {alarmId ? <Details refetch={refetch} alarmId={alarmId} /> : null}
    </div>
  )
}
