import React from 'react'
import Select from 'orionsoft-parts/lib/components/fields/Select'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import useOrganizationId from 'App/hooks/useOrganizationId'

export default function Entities(props) {
  const {entities} = useQuery(
    gql`
      query getEntitiesForSelect($organizationId: ID) {
        entities(organizationId: $organizationId) {
          _id
          items {
            _id
            name
            rut
          }
        }
      }
    `,
    {organizationId: useOrganizationId()}
  )
  const options = entities.items.map(entity => ({
    label: `${entity.name} ${entity.rut}`,
    value: entity._id
  }))
  return <Select {...props} options={options} multi />
}
