import React from 'react'
import styles from './styles.css'
import MutationButton from 'App/components/MutationButton'
import useHasPermissions from 'App/hooks/useHasPermissions'

const labels = {admin: 'Admin', viewer: 'Visor', editor: 'Editor'}

export default function User(props) {
  const {user, role, _id: roleId} = props.user
  const canView = useHasPermissions('admin')
  return (
    <div className={styles.container}>
      <div className={styles.role}>{labels[role]}</div>
      <div className={styles.name}>{user.name}</div>
      <div className={styles.email}>{user.email}</div>
      {canView ? (
        <div>
          <br />
          <MutationButton
            label="Eliminar usuario"
            title="Eliminar usuario"
            confirmText="Eliminar usuario"
            message="Seguro que quieres eliminar este usuario de esta organización?"
            params={{roleId}}
            mutation="deleteRole"
            danger
            onSuccess={props.refetch}
          />
        </div>
      ) : null}
    </div>
  )
}
