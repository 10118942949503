'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (options) {
  var links = [(0, _onError2.default)(options)];

  if (options.useSubscriptions) {
    var httpLink = (0, _createHttpLink2.default)(options);
    var wsLink = (0, _createWsLink2.default)(options);
    var link = (0, _apolloLink.split)(function (_ref) {
      var query = _ref.query;

      var _getMainDefinition = (0, _apolloUtilities.getMainDefinition)(query),
          kind = _getMainDefinition.kind,
          operation = _getMainDefinition.operation;

      return kind === 'OperationDefinition' && operation === 'subscription';
    }, wsLink, httpLink);
    links.push(link);
  } else {
    links.push((0, _createHttpLink2.default)(options));
  }

  return _apolloLink.ApolloLink.from(links);
};

var _apolloLink = require('apollo-link');

var _createHttpLink = require('./createHttpLink');

var _createHttpLink2 = _interopRequireDefault(_createHttpLink);

var _onError = require('./onError');

var _onError2 = _interopRequireDefault(_onError);

var _createWsLink = require('./createWsLink');

var _createWsLink2 = _interopRequireDefault(_createWsLink);

var _apolloUtilities = require('apollo-utilities');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}