import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Tabs from 'orionsoft-parts/lib/components/Tabs'
import Info from './Info'
import Update from './Update'
import styles from './styles.css'
import Alarms from './Alarms'
import useHasPermissions from 'App/hooks/useHasPermissions'

export default function Options(props) {
  const {contract} = props
  const basePath = `/${contract.organizationId}/contracts/${contract._id}`
  const hasPermissions = useHasPermissions('editor')
  return (
    <div className={styles.container}>
      <Tabs
        items={[
          {title: 'Información', path: basePath + ''},
          ...(hasPermissions ? [{title: 'Editar', path: basePath + '/update'}] : []),
          {title: 'Alarmas', path: basePath + '/alarms'}
        ]}
      />
      <Switch>
        <Route path="/:organizationId/contracts/:contractId" exact component={Info} />
        <Route path="/:organizationId/contracts/:contractId/update" component={Update} />
        <Route path="/:organizationId/contracts/:contractId/alarms/:alarmId?" component={Alarms} />
      </Switch>
    </div>
  )
}
