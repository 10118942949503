import React from 'react'
import styles from './styles.css'
import Alarm from './Alarm'
import useState from 'App/hooks/useState'

export default function List(props) {
  const [showResolved, setShowResolved] = useState(false)
  const hasResolved = props.contract.alarms.find(alarm => !!alarm.resolvedAt)
  return (
    <div className={styles.container}>
      {props.contract.alarms
        .filter(alarm => {
          if (showResolved) return true
          return !alarm.resolvedAt
        })
        .map(alarm => (
          <Alarm key={alarm._id} alarm={alarm} refetch={props.refetch} />
        ))}
      {hasResolved ? (
        <a className={styles.button} onClick={() => setShowResolved(!showResolved)}>
          {showResolved ? 'Mostrar solo futuras' : 'Mostrar alarmas resueltas también'}
        </a>
      ) : null}
    </div>
  )
}
