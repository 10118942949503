import React from 'react'
import MutationButton from 'App/components/MutationButton'
import styles from './styles.css'
import Text from 'orionsoft-parts/lib/components/fields/Text'
import useState from 'App/hooks/useState'

export default function Delete(props) {
  const [resolvedText, setResolvedText] = useState()
  const {alarm, refetch} = props
  if (!alarm.isActive && !alarm.isDue) return null
  return (
    <div className={styles.container}>
      <MutationButton
        label="Resolver alarma"
        title="Resolver alarma"
        confirmText="Resolver alarma"
        message=""
        params={{alarmId: alarm._id, resolvedText}}
        mutation="resolveAlarm"
        primary
        onSuccess={async () => {
          await refetch()
        }}>
        <div>
          <div>Marcar esta alarma como resuelta</div>
          <br />
          <div className="description">Indica como se resolvió la alarma</div>
          <Text value={resolvedText} onChange={setResolvedText} placeholder="Como se resolvió" />
        </div>
      </MutationButton>
      <div className="divider" />
    </div>
  )
}
