import React from 'react'
import styles from './styles.css'
import Delete from './Delete'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import Breadcrumbs from 'App/components/Breadcrumbs'
import Container from 'orionsoft-parts/lib/components/Container'
import Update from './Update'
import Emails from './Emails'

export default function Organization(props) {
  const {areaId} = props.match.params
  const {area} = useQuery(
    gql`
      query getArea($areaId: ID) {
        area(areaId: $areaId) {
          _id
          name
          organizationId
        }
      }
    `,
    {areaId}
  )
  return (
    <div className={styles.container}>
      <Breadcrumbs past={{[`/${area.organizationId}/areas`]: 'Áreas'}}>{area.name}</Breadcrumbs>
      <br />
      <Container>
        <Update areaId={areaId} />
        <Emails areaId={areaId} />
        <Delete areaId={areaId} />
      </Container>
    </div>
  )
}
