import React from 'react'
import Select from 'orionsoft-parts/lib/components/fields/Select'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import useOrganizationId from 'App/hooks/useOrganizationId'

export default function Area(props) {
  const {areas} = useQuery(
    gql`
      query getOrganizationAreas($organizationId: ID) {
        areas(organizationId: $organizationId, onlyParticipating: true) {
          items {
            value: _id
            label: name
          }
        }
      }
    `,
    {organizationId: useOrganizationId()}
  )
  return <Select {...props} options={areas.items} />
}
