import React from 'react'
import styles from './styles.css'
import useQuery from 'apollo-hooks/lib/useQuery'
import gql from 'graphql-tag'
import AutoForm from 'App/components/AutoForm'
import useRef from 'App/hooks/useRef'
import Button from 'orionsoft-parts/lib/components/Button'
import useMessage from 'App/hooks/useMessage'
import SuspenseLoading from 'App/components/SuspenseLoading'
import useRouter from 'App/hooks/useRouter'
import Delete from '../Info/Delete'

export default function Update(props) {
  const {contractId} = props.match.params
  const showMessage = useMessage()
  const {history} = useRouter()
  const {contract} = useQuery(
    gql`
      query getContract($contractId: ID) {
        contract(contractId: $contractId) {
          _id
          areaId
          area {
            _id
            name
          }
          name
          description
          contractType
          signDate
          notary
          repertory
          dueDate
          signersIds
          otherSignersEntitiesIds
          otherSignersText
          status
          price
          pricePeriodicity
        }
      }
    `,
    {contractId}
  )
  const form = useRef()

  return (
    <div className={styles.container}>
      <SuspenseLoading>
        <AutoForm
          mutation="updateContract"
          ref={form}
          only="contract"
          doc={{contractId, contract}}
          onSuccess={contract => {
            showMessage('Documento actualizado')
            history.push(`/${contract.organizationId}/contracts/${contract._id}`)
          }}
        />
      </SuspenseLoading>
      <br />
      <Delete contractId={contract._id} />
      <Button onClick={() => form.current.submit()} primary>
        Guardar
      </Button>
    </div>
  )
}
