import React from 'react'
import styles from './styles.css'
import Breadcrumbs from 'App/components/Breadcrumbs'
import Container from 'orionsoft-parts/lib/components/Container'
import File from 'App/components/fields/UploadContract'
import useMutation from 'apollo-hooks/lib/useMutation'
import gql from 'graphql-tag'
import useMessage from 'App/hooks/useMessage'
import useRouter from 'App/hooks/useRouter'
import useOrganizationId from 'App/hooks/useOrganizationId'
import useState from 'App/hooks/useState'
import Loading from 'orionsoft-parts/lib/components/Loading'

export default function CreateContract(props) {
  const showMessage = useMessage()
  const organizationId = useOrganizationId()
  const [loading, setLoading] = useState()
  const {history} = useRouter()
  const mutate = useMutation(gql`
    mutation createContract($organizationId: ID, $fileId: ID) {
      contract: createContract(organizationId: $organizationId, fileId: $fileId) {
        _id
        organizationId
      }
    }
  `)

  const createContract = async file => {
    setLoading(true)
    try {
      const {contract} = await mutate({
        fileId: file._id,
        organizationId
      })
      setLoading(false)
      showMessage('Documento creado')
      history.push(`/${organizationId}/contracts/${contract._id}/update`)
    } catch (error) {
      setLoading(false)
      showMessage(error)
    }
  }

  return (
    <div className={styles.container}>
      <Breadcrumbs>Crear documento</Breadcrumbs>
      <br />
      <br />
      <Container size="small">
        <div className="content">
          <h3>Crear documento</h3>
          {loading ? (
            <div className={styles.loadingContainer}>
              <Loading />
              <div>Procesando documento</div>
            </div>
          ) : (
            <div>
              <File onChange={createContract} />
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}
