import React from 'react'
import styles from './styles.css'
import {Route, Switch} from 'react-router-dom'
import List from './List'

export default function Areas() {
  return (
    <div className={styles.container}>
      <Switch>
        <Route path="/:organizationId/alarms" exact component={List} />
      </Switch>
    </div>
  )
}
