import React from 'react'
import styles from './styles.css'
import PaginatedList from 'App/components/Crud/List'
import Button from 'orionsoft-parts/lib/components/Button'
import useOrganizationId from 'App/hooks/useOrganizationId'
import useHasPermissions from 'App/hooks/useHasPermissions'

export default function Users(props) {
  const fields = [
    {name: 'name', title: 'Nombre'},
    {
      name: 'emails',
      title: 'Participantes',
      render: ({emails}) => {
        if (!emails || !emails.length) return '-'
        const s = emails.length === 1 ? 's' : ''
        return `${emails.length} participante${s}`
      }
    }
  ]
  const hasPermissions = useHasPermissions('admin')
  const organizationId = useOrganizationId()
  return (
    <div className={styles.container}>
      <PaginatedList
        title="Áreas"
        name="areas"
        canUpdate={hasPermissions}
        params={{organizationId}}
        centerContent={
          hasPermissions ? (
            <Button primary to={`/${organizationId}/areas/create`}>
              Crear
            </Button>
          ) : null
        }
        fields={fields}
        allowSearch
        basePath={`/${organizationId}/areas`}
      />
    </div>
  )
}
