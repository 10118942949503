'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  var _getOptions = (0, _options.getOptions)(),
      getSession = _getOptions.getSession;

  return getSession();
};

var _options = require('../options');