import React from 'react'
import styles from './styles.css'
import useRef from 'App/hooks/useRef'
import useComponentWidth from 'App/hooks/useComponentWidth'
import Image from './Image'
import useState from 'App/hooks/useState'
import debounce from 'lodash/debounce'

export default function View(props) {
  const {pages, _id} = props.contract
  const container = useRef()
  const [scrollTop, setScrollTop] = useState(0)
  const width = useComponentWidth(container)
  const content = width
    ? pages.map((page, index) => {
        const height = (page.height * width) / page.width
        const top = height * (index - 1)
        const bottom = height * (index + 1)
        const visibleTop = scrollTop >= top
        const visibleBottom = scrollTop < bottom
        return (
          <div key={index} className={styles.page} style={{height}}>
            <Image contractId={_id} page={index + 1} visible={visibleTop && visibleBottom} />
          </div>
        )
      })
    : null

  const deferredScrollTop = debounce(() => {
    setScrollTop(container.current.scrollTop)
  }, 300)

  const onScroll = event => {
    if (container.current.scrollTop !== scrollTop) {
      deferredScrollTop()
    }
  }
  return (
    <div ref={container} className={styles.container} onScroll={onScroll}>
      {content}
    </div>
  )
}
