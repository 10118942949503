import React from 'react'
import styles from './styles.css'
import {Route, Switch} from 'react-router-dom'
import List from './List'
import Create from './Create'
import Area from './Area'

export default function Areas() {
  return (
    <div className={styles.container}>
      <Switch>
        <Route path="/:organizationId/areas" exact component={List} />
        <Route path="/:organizationId/areas/create" exact component={Create} />
        <Route path="/:organizationId/areas/:areaId" exact component={Area} />
      </Switch>
    </div>
  )
}
