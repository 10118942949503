import ContractsIcon from 'react-icons/lib/md/inbox'
import ConfigurationIcon from 'react-icons/lib/md/settings'

export default [
  {
    title: 'Documentos',
    icon: ContractsIcon,
    items: [
      {title: 'Documentos', path: '/contracts', role: 'viewer'},
      {title: 'Alarmas', path: '/alarms', role: 'viewer'}
    ]
  },
  {
    title: 'Configuración',
    icon: ConfigurationIcon,
    items: [
      {title: 'Organización', path: '/organization-settings', role: 'admin'},
      {title: 'Áreas', path: '/areas', role: 'viewer'},
      {title: 'Firmantes', path: '/signers', role: 'viewer'},
      {title: 'Contrapartes', path: '/entities', role: 'viewer'},
      {title: 'Usuarios', path: '/users', role: 'viewer'}
    ]
  }
]
