import React from 'react'
import {Route, Switch} from 'react-router-dom'
import List from './List'
import Contract from './Contract'

export default function App(props) {
  return (
    <Switch>
      <Route path="/:organizationId/contracts" exact component={List} />
      <Route path="/:organizationId/contracts/:contractId" component={Contract} />
    </Switch>
  )
}
