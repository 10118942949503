import React, {Fragment} from 'react'
import styles from './styles.css'
import PaginatedList from 'App/components/Crud/List'
import moment from 'moment'
import useOrganizationId from 'App/hooks/useOrganizationId'
import statusLabels from 'App/helpers/labels/status'
import Tooltip from 'orionsoft-parts/lib/components/Tooltip'
import numeral from 'numeral'
import AlarmIcon from 'react-icons/lib/md/alarm'
import useState from 'App/hooks/useState'
import Filter from './Filter'
import Export from './Export'
import Header from 'App/components/Header'

export default function Contracts(props) {
  const organizationId = useOrganizationId()
  const [filters, setFilters] = useState({})
  const fields = [
    {
      name: '_id',
      title: (
        <div className={styles.indicatorContainer}>
          <AlarmIcon size={18} />
        </div>
      ),
      render: ({numberOfActiveAlarms, numberOfDueAlarms, numberOfAlarms}) => {
        let color = '#ccc'
        const texts = []
        if (numberOfAlarms) {
          color = 'green'
        }
        if (numberOfActiveAlarms) {
          color = 'orange'
          const s = numberOfActiveAlarms === 1 ? '' : 's'
          texts.push(`${numeral(numberOfActiveAlarms).format('0,0')} alarma${s} activada${s}`)
        }
        if (numberOfDueAlarms) {
          color = 'red'
          const s = numberOfDueAlarms === 1 ? '' : 's'
          texts.push(`${numeral(numberOfDueAlarms).format('0,0')} alarma${s} vencida${s}`)
        }
        const defaultText = numberOfAlarms
          ? 'No se han activado alarmas'
          : 'No se han definido alarmas'
        return (
          <div className={styles.indicatorContainer}>
            <Tooltip content={texts.length ? texts.join(' y ') : defaultText}>
              <div className={styles.indicator} style={{backgroundColor: color}} />
            </Tooltip>
          </div>
        )
      }
    },
    {name: 'number', title: 'ID'},
    {name: 'name', title: 'Nombre'},
    {name: 'contractType', title: 'Tipo'},
    {name: 'area.name', title: 'Área'},
    {
      name: 'signers.name',
      title: 'Firmado por',
      render: ({signers}) =>
        signers.map((entity, index) => (
          <Fragment key={entity._id}>
            <Tooltip content={entity.rut}>{entity.name}</Tooltip>
            {index === signers.length - 1 ? '' : ', '}
          </Fragment>
        ))
    },
    {
      name: 'otherSignersEntities.name',
      title: 'Contraparte',
      render: ({otherSignersEntities}) =>
        otherSignersEntities.map((entity, index) => (
          <Fragment key={entity._id}>
            <Tooltip content={entity.rut}>{entity.name}</Tooltip>
            {index === otherSignersEntities.length - 1 ? '' : ', '}
          </Fragment>
        ))
    },
    {name: 'numberOfAlarms', title: 'Alarmas', render: ({numberOfAlarms}) => numberOfAlarms || '-'},
    {name: 'status', title: 'Estado', render: ({status}) => (status ? statusLabels[status] : '-')},
    {
      name: 'price',
      title: 'Precio',
      render: ({price, pricePeriodicity}) => {
        if (!price) return '-'
        const periodsLabels = {
          day: 'día',
          week: 'semana',
          month: 'mes',
          year: 'año'
        }
        const period = pricePeriodicity ? `/${periodsLabels[pricePeriodicity]}` : ''
        return numeral(price).format('$0,0') + period
      }
    },
    {
      name: 'signDate',
      title: 'Firmado',
      render: ({signDate}) => (signDate ? moment(signDate).format('DD-MM-YYYY') : '-')
    }
  ]
  return (
    <div className={styles.container}>
      <Header title="Documentos">
        <Filter filters={filters} setFilters={setFilters} />
      </Header>
      <PaginatedList
        title={null}
        name="contracts"
        centerContent={null}
        canUpdate
        allowSearch={false}
        params={{organizationId, ...filters}}
        fields={fields}
        extraFields={[
          'signers._id',
          'signers.rut',
          'otherSignersEntities._id',
          'otherSignersEntities.rut',
          'numberOfActiveAlarms',
          'numberOfDueAlarms',
          'pricePeriodicity',
          'area._id'
        ]}
        basePath={`/${organizationId}/contracts`}
      />
      <Export params={{organizationId, ...filters}} />
    </div>
  )
}
