import React, {Fragment} from 'react'
import styles from './styles.css'
import moment from 'moment'
import {Link} from 'react-router-dom'
import Resolve from './Resolve'
import NewLines from 'App/components/NewLines'
import useHasPermissions from 'App/hooks/useHasPermissions'

export default function Alarm(props) {
  const {alarm, refetch} = props
  const hasPermissions = useHasPermissions('editor')

  return (
    <div className={styles.container}>
      {hasPermissions ? <Resolve alarm={alarm} refetch={refetch} /> : null}

      <div className={styles.name}>{alarm.alarmType}</div>
      <div className={styles.description}>
        <NewLines>{alarm.description}</NewLines>
      </div>
      <div className={styles.text}>
        <NewLines>{alarm.text}</NewLines>
      </div>
      <div className="divider" />
      {alarm.resolvedAt ? (
        <div className={styles.date}>Resuelta: {moment(alarm.resolvedAt).format('LL')}</div>
      ) : null}
      {alarm.resolvedByName ? (
        <div className={styles.date}>Resuelta por: {alarm.resolvedByName}</div>
      ) : null}
      {alarm.resolvedText ? <div className={styles.date}>Mensaje: {alarm.resolvedText}</div> : null}
      <div className={alarm.isActive || alarm.isDue ? styles.activeDate : styles.date}>
        Se activa: {moment(alarm.notifyDate).format('LL')}
      </div>
      <div className={alarm.isDue ? styles.activeDate : styles.date}>
        Fecha: {moment(alarm.dueDate).format('LL')}
      </div>
      {hasPermissions ? (
        <Fragment>
          <div className="divider" />
          <Link to={`/${alarm.organizationId}/contracts/${alarm.contractId}/alarms/${alarm._id}`}>
            Ver detalles
          </Link>
        </Fragment>
      ) : null}
    </div>
  )
}
