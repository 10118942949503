'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

exports.default = function (passedOptions) {
  var options = _extends({}, defaultOptions, passedOptions);

  (0, _options.setOptions)(options);
  var link = (0, _createLink2.default)(options);
  var client = new _apolloClient.ApolloClient({
    link: link,
    cache: options.cache
  });
  global.apolloClient = client;
  options.apolloClient = client;
  (0, _options.setOptions)(options);
  return client;
};

var _apolloClient = require('apollo-client');

var _defaultCache = require('./defaultCache');

var _defaultCache2 = _interopRequireDefault(_defaultCache);

var _createLink = require('./createLink');

var _createLink2 = _interopRequireDefault(_createLink);

var _options = require('../options');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var defaultOptions = {
  endpointURL: 'http://localhost:3000',
  subscriptionsPath: '/subscriptions',
  useSubscriptions: true,
  saveSession: function saveSession() {
    throw new Error('You must pass a function to save the session');
  },
  getSession: function getSession() {
    throw new Error('You must pass a function to get the saved session');
  },
  cache: _defaultCache2.default,
  batchInterval: 20,
  batch: true,
  canRetry: true,
  promptTwoFactorCode: function promptTwoFactorCode() {
    return global.prompt('Please write your two factor code to continue');
  },
  onError: function onError() {},
  getHeaders: function getHeaders() {}
};