'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (text, session) {
  if (!session) return {};
  var publicKey = session.publicKey,
      secretKey = session.secretKey;
  if (!publicKey || !secretKey) return {};
  var shaObj = new _jssha2.default('SHA-512', 'TEXT');
  shaObj.setHMACKey(secretKey, 'TEXT');
  shaObj.update(text);
  var signature = shaObj.getHMAC('HEX');
  return signature;
};

var _jssha = require('jssha');

var _jssha2 = _interopRequireDefault(_jssha);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}