"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var options = {};

var setOptions = exports.setOptions = function setOptions(newOptions) {
  options = newOptions;
};

var getOptions = exports.getOptions = function getOptions() {
  return options;
};