import React from 'react'
import styles from './styles.css'
import Breadcrumbs from 'App/components/Breadcrumbs'
import Add from './Add'
import List from './List'
import Container from 'orionsoft-parts/lib/components/Container'

export default class Main extends React.Component {
  static propTypes = {}

  render() {
    return (
      <div className={styles.container}>
        <Breadcrumbs>Usuarios en la organización</Breadcrumbs>
        <br />
        <Container>
          <Add />
          <List />
        </Container>
      </div>
    )
  }
}
